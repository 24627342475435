import React from 'react';
import PropTypes from 'prop-types';
import { url } from '../../../config/env';

import {
  HULU_TERMS,
  HULU_PRIVACY_POLICY,
  WALT_DISNEY_FOC,
  SUBSCRIBER_AGREEMENT,
} from '../constants/terms';

export default class AccountInfoTerms extends React.Component {
  render() {
    const privacyUrl = this.props.isTWDCPrivacyPolicyFeatureOn
      ? url.privacyHtmlTWDC
      : url.privacyHtml;
    const termsLink = (
      <a className="terms-link" href={url.tosHtml} target="_blank">
        {HULU_TERMS}
      </a>
    );
    const privacyLink = (
      <a className="privacy-link" href={privacyUrl} target="_blank">
        {HULU_PRIVACY_POLICY}
      </a>
    );
    const twdcLink = (
      <a className="twdc-link" href={url.disneyFOC} target="_blank">
        {WALT_DISNEY_FOC}
      </a>
    );
    const twdcTermsLink = (
      <a className="twdc-terms-link" href={url.tosHtml} target="_blank">
        {SUBSCRIBER_AGREEMENT}
      </a>
    );
    const huluLegalTerms = (
      <div className="terms">
        By clicking &ldquo;{this.props.ctaText}&rdquo; you agree to the{' '}
        {termsLink} and {privacyLink}.
      </div>
    );
    const twdcLegalTerms = (
      <div className="terms">
        Hulu will use your data to personalize and improve your Hulu experience
        and to send you information about Hulu. You can change your
        communication preferences at any time. We may use your data as described
        in our {privacyLink}, including sharing it with {twdcLink}. By clicking
        &ldquo;{this.props.ctaText}&rdquo; below you agree to our{' '}
        {twdcTermsLink} and acknowledge that you have read our {privacyLink}.
      </div>
    );

    return (
      <span data-testid="legalese-body">
        {this.props.shouldShowTwdcLegalTerms ? twdcLegalTerms : huluLegalTerms}
      </span>
    );
  }
}

AccountInfoTerms.propTypes = {
  ctaText: PropTypes.string.isRequired,
  shouldShowTwdcLegalTerms: PropTypes.bool,
  isTWDCPrivacyPolicyFeatureOn: PropTypes.bool,
};
