import { connect } from 'react-redux';

import {
  handleBillingPageLoaded,
  handleBillingPageTabSelect,
  handleBillingPageSubmit,
} from '../actions/billingActions';
import { WEB_25275 } from '../constants/experiments';
import track from '../metrics/track';
import BillingInfoPage from '../pages/BillingInfoPage';
import {
  getIsBillingPageLoading,
  getBillingCtaButtonText,
  getIsSkipBillingFlow,
  getBillingTabIndex,
} from '../selectors/billingPage';
import { hasAssignment } from '../selectors/config';
import {
  getIsGoogleAndroid,
  getIsUnifiedActivationFlow,
} from '../selectors/flow';
import {
  getDisplayPaymentFields,
  getVermontPrepaidConsentText,
} from '../selectors/ledger';
import { getControlText } from '../selectors/siteconfig';
import {
  canSubmitBillingInfo,
  getCreateSubscriberInProgress,
} from '../selectors/subscription';
import { getSelectedPlan } from '../selectors/user';
import { getIsIneligibleDiscount } from '../selectors/warning';
import { isCuriosityIncluded } from '../selectors/plan';

const mapStateToProps = state => ({
  tabIndex: getBillingTabIndex(state),
  isLoading: getIsBillingPageLoading(state),
  canSubmit: canSubmitBillingInfo(state),
  createSubscriberInProgress: getCreateSubscriberInProgress(state),
  isCuriosityIncluded: isCuriosityIncluded(getSelectedPlan(state)),
  isIneligibleDiscount: getIsIneligibleDiscount(state),
  displayPaymentFields: getDisplayPaymentFields(state),
  onlyCCSupported: getIsGoogleAndroid(state),
  buttonText: getBillingCtaButtonText(state),
  liveConsent: getControlText(state, 'live_consent'),
  isSkipBillingFlow: getIsSkipBillingFlow(state),
  vermontConsent: getVermontPrepaidConsentText(state),
  isUnifiedActivationFlow: getIsUnifiedActivationFlow(state),
  // TODO WEB-26811: Clean up experiment - remove this code
  isInCancelAnytimeTreatmentOne: hasAssignment(
    state,
    WEB_25275.CANCEL_ANYTIME_COPY
  ),
  isInCancelAnytimeTreatmentTwo: hasAssignment(state, WEB_25275.SECURE_COPY),
  isInCancelAnytimeTreatmentThree: hasAssignment(
    state,
    WEB_25275.CANCEL_SECURE_COPY
  ),
});

const mapDispatchToProps = dispatch => ({
  onLoaded: () => dispatch(handleBillingPageLoaded()),
  onTabSelect: index => dispatch(handleBillingPageTabSelect(index)),
  onSubmit: () => dispatch(handleBillingPageSubmit()),
});

// TODO WEB-26811: Clean up experiment - remove this code
const mergeProps = (
  {
    isInCancelAnytimeTreatmentOne,
    isInCancelAnytimeTreatmentTwo,
    isInCancelAnytimeTreatmentThree,
    ...stateProps
  },
  { ...dispatchProps }
) => {
  let billingCancelAnytimeExperimentTreatment = '';

  if (isInCancelAnytimeTreatmentOne) {
    billingCancelAnytimeExperimentTreatment = WEB_25275.CANCEL_ANYTIME_COPY; // only 'cancel anytime' copy
  } else if (isInCancelAnytimeTreatmentTwo) {
    billingCancelAnytimeExperimentTreatment = WEB_25275.SECURE_COPY; // only 'secure and encrypted' copy
  } else if (isInCancelAnytimeTreatmentThree) {
    billingCancelAnytimeExperimentTreatment = WEB_25275.CANCEL_SECURE_COPY; // both cancel and secure copy together
  }

  return {
    billingCancelAnytimeExperimentTreatment,
    ...stateProps,
    ...dispatchProps,
  };
};

export default track(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(BillingInfoPage)
);
