import { getLedger } from './ledger';

// TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment
export const getIsAltLedgerLoading = state => state.altLedger?.loading;
export const getAltLedger = state => state.altLedger?.altLedger;

/**
 * This function takes in a ledger object from state and
 * creates the switchToCopy, and optional priceDiff and disclaimer copy values
 * which will be displayed in the alternate ledger section of the screen.
 * The priceDiff is calculated by comparing the plan prices.
 * If the ledger plan is MAX NOAH, there will be an added disclaimer.
 *
 * If the ledger plan contains ads:
 *   switchToCopy: 'Switch to {PLAN NAME}'
 *
 * If the ledger plan does not contain ads:
 *   switchToCopy: 'Switch to {PLAN NAME}'
 *   priceDiff: '+${DIFFERENCE IN PLAN PRICES}/mo'
 *
 *
 * @param {*} ledger - Redux Store Ledger object for the alternate ledger.
 * @param {*} otherPrice - Price of the currently selected ledger.
 * @returns Object containing copy values to be displayed.
 */
function createAltLedgerCopyObject(ledger, otherPrice) {
  const originalPlanName = ledger?.subscription.plan.name;
  const planPriceObject = ledger?.subscription.plan.price;
  const planPriceAmount = planPriceObject?.money.amount;

  let planName = originalPlanName;

  let priceDiff;
  let disclaimer;
  let details;

  switch (originalPlanName) {
    case 'Hulu':
      planName = 'Hulu (With Ads)';
      break;
    case 'Hulu (No Ads) + Live TV':
      disclaimer = 'Ads will be served in select live and linear content';
      break;
    case 'Hulu + Live TV, Disney+, and ESPN+':
      planName =
        'Hulu (With Ads) + Live TV, Disney+ (With Ads), and ESPN+ (With Ads)';
      break;
    case 'Disney+, Hulu, Max Bundle (No Ads)':
      disclaimer = 'Ads will be served in select live and linear content';
      break;
    case 'Disney Bundle Duo Premium':
      details = 'Switch to Disney+ (No Ads), Hulu (No Ads)';
      disclaimer = 'Ads will be served in select live and linear content';
      break;
    case 'Disney Bundle Trio Premium':
      details = 'Switch to Disney+ (No Ads), Hulu (No Ads), ESPN+ (With Ads)';
      disclaimer = 'Ads will be served in select live and linear content';
      break;
    default:
      break;
  }

  if (planPriceAmount > otherPrice) {
    const diffValue = (planPriceAmount - otherPrice).toFixed(2);
    priceDiff = `+$${diffValue}/mo`;
  }

  return {
    planName,
    priceDiff,
    disclaimer,
    details,
  };
}

/**
 * This function pulls the ledger and altLedger objects from the redux state
 * and creates objects to hold the copy values to be displayed for each plan's
 * alternate ledger section on the page.
 *
 * If ledger or altLedger are not defined in state, return null for both.
 * Also, if the price object is undefined for either plan, return null for both.
 * This will result in the alt ledger section not displaying on the page.
 *
 * @returns Object containing the alternate ledger copy for the current and alternate ledger plans.
 */
export function getAltLedgerCopy(state) {
  const altLedger = getAltLedger(state);
  const ledger = getLedger(state);

  // If either plan is undefined OR either plan has an undefined price object,
  // we will not show the Upgrade/Downgrade feature for this use case.
  // This covers use cases where there is no alternate ledger available
  // and when one of the two plans is a sponsored redemption offer
  if (!ledger?.subscription.plan?.price || !altLedger?.subscription.plan) {
    return {
      alternatePlanCopy: null,
      currentPlanCopy: null,
    };
  }

  const ledgerPlanPrice = ledger?.subscription.plan?.price?.money?.amount;
  const altLedgerPlanPrice = altLedger?.subscription.plan?.price?.money?.amount;

  const alternatePlanCopy = createAltLedgerCopyObject(
    altLedger,
    ledgerPlanPrice
  );
  const currentPlanCopy = createAltLedgerCopyObject(ledger, altLedgerPlanPrice);

  return {
    alternatePlanCopy,
    currentPlanCopy,
  };
}
