/* the below constants refer to jira tickets describing the experiences.
  CAT constants refer to the Catalyst Jira board.
  SUMO constants refer to the WST / Sumo Jira board.
  BRAND constants refer to the BRAND ticket created by product.
  WEB constants refer to the WEB Jira board.
  The key / value pairs help us target different behavior within the same experiment.
*/

export const CAT_113 = {
  TREATMENT1: 'cat-113-t1',
  TREATMENT2: 'cat-113-t2',
};

export const CAT_43 = {
  TREATMENT1: 'cat-43-t1',
  TREATMENT2: 'cat-43-t2',
};

export const CAT_90 = {
  TREATMENT1: 'cat-90-t1',
};

export const SUMO_5310 = {
  CHECKBOXES: 'sumo-5310-checkboxes',
  UPSELLPAGE: 'sumo-5310-upsellpage',
};

export const CAT_277_V3 = {
  TREATMENT1: 'cat-277-v3-TREATMENT1',
  TREATMENT2: 'cat-277-v3-TREATMENT2',
};

// Experiment to show prepaid plan options when available.
export const CAT_357 = {
  PLAN_OPTION_PAGE: 'cat-357-t1',
  PREPAID_ON_BILLING: 'cat-357-t2',
};

// Experiment to show user-type badges on NOAH and SASH_LIVE
export const CAT_345 = {
  USER_TYPE_BADGE: 'cat-345-user-type-badge',
};

// Experiment to change language on plan cards
export const CAT_321 = {
  ZERO: 'cat-321-zero',
};

// Experiment to change sub header on Account creation page
export const CAT_335 = {
  SUBHEADER: 'cat-335-customer-value-sub-header',
};

// Experiment to change D+ Bundle CTA copy on Plan Select page
export const HULU_11864 = {
  CTA_COPY: 'hulu-11864-get-bundle-copy',
};

// Experiment to update subheader on billing page
export const CAT_336 = {
  SUBHEADER: 'cat-336-update-subheader',
};

// Experiment to show SASH_LIVE plan as second on Plan Select page
export const HUWEB_20642 = {
  LIVE_PRIORITY: 'huweb-20642-live-priority',
};

// Experiment for Bundle Badges on Plan Select Page
export const HULU_13632 = {
  BADGES: 'hulu-13632-badges',
};

// Experiment for Most Popular Badge for SASH Bundle
export const HULU_13876 = {
  BEST_VALUE: 'hulu-13876-best-value',
};

export const HULU_13873 = {
  TABS_ON_MW: 'hulu-13873-tabs-on-mw',
};

export const HULU_14131 = {
  VALUE_PROP_BULLETS: 'hulu-14131-value-prop-bullets',
};

export const HULU_14425 = {
  SUF_DEFAULT_3UP: 'hulu-14425-3up-suf-default',
};

export const WEB_16490 = {
  EDNA_CREATE_ACCOUNT: 'edna-treatment',
  CONTROL_CREATE_ACCOUNT: 'edna-control',
};

export const WEB_20035 = {
  SUF_WEB_DEFAULT_3UP: 'web-20035-suf-3up',
};

// TODO WEB-24281: Cleanup after Hulu Ads Pref Experiment
export const WEB_24169 = {
  TREATMENT1: 'ads-pref-on-billing-treatment-1', // toggle UI
  TREATMENT2: 'ads-pref-on-billing-treatment-2', // button UI
};

// TODO WEB-24424: cleanup LiveTV Logo experiment code
export const WEB_24174 = {
  SHOW_NEW_LIVE_LOGO: 'web-24174-show-new-livetv-logo',
};

// TODO WEB-26131 : cleanup after Max Bundle on Plan Select experiment
export const WEB_25453 = {
  MAX_WITH_BANNER: 'max-bundle-hulu-plan-select-t1',
  MAX_WITHOUT_BANNER: 'max-bundle-hulu-plan-select-t2',
};

export const WEB_25797 = {
  HULU_WITH_EMAIL_CONFIRMATION: 'email-confirmation-modal',
  HULU_WITHOUT_EMAIL_CONFIRMATION: 'email-confirmation-control',
};

// TODO WEB-26811: Clean up experiment - remove this code
// Staging Spearmint: https://spearmint.staging.hulu.com/experiments/1788
export const WEB_25275 = {
  CANCEL_ANYTIME_COPY: 'great-cancel-anytime-secure-payment-icon-billing-t1',
  SECURE_COPY: 'great-cancel-anytime-secure-payment-icon-billing-t2',
  CANCEL_SECURE_COPY: 'great-cancel-anytime-secure-payment-icon-billing-t3',
};
