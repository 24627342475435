import React from 'react';
import PropTypes from 'prop-types';
import { WEB_25275 } from '../constants/experiments';

require('./billingCancelCopy.scss');

/**
 * For WEB-25275. This experiment displays copy under the 'Submit' button on the Sign up flow billing page.
 *
 * Treatment 1 - the user sees the cancel anytime copy
 * Treatment 2 - the user sees the secure and encrypted copy
 * Treatment 3 - the user sees both the cancel anytime and secure and encrypted copy
 *
 * @param {*} treatment - Treatment ID for the Experiment
 */
const BillingCancelAndSecureCopy = ({ treatment }) => {
  if (!treatment) {
    return <></>;
  }

  return (
    <div className="cancel-container">
      {(treatment === WEB_25275.CANCEL_ANYTIME_COPY ||
        treatment === WEB_25275.CANCEL_SECURE_COPY) && (
        <p className="cancel-anytime-copy">Cancel anytime</p>
      )}
      {(treatment === WEB_25275.SECURE_COPY ||
        treatment === WEB_25275.CANCEL_SECURE_COPY) && (
        <div className="secure-copy">
          <img alt="Lock Icon" src="/static/images/hulu-lock-icon-locked.svg" />
          <p>Secure and encrypted</p>
        </div>
      )}
    </div>
  );
};

BillingCancelAndSecureCopy.propTypes = {
  treatment: PropTypes.string,
};

export default BillingCancelAndSecureCopy;
