/*
 * Common Events
 *
 * Sent by more than one tracker
 */
export const SUBSCRIPTION_START = 'subscription_start';
export const SUBSCRIPTION_END = 'subscription_end';
export const SUBSCRIPTION_STEP_START = 'subscription_step_start';
export const SUBSCRIPTION_STEP_END = 'subscription_step_end';
export const AGE_ROADBLOCK = 'age_roadblock';

/*
 * Site Metrics Events
 *
 * Sent by sufo-redux SiteMetricsTracker
 *
 * These events are sent via the `@hulu/site-metrics` library, which
 * are routed to Vortex owned by the DNA #instrumentation team. Events
 * fired by Site Metrics must be in the client metrics config whitelist.
 *
 * See the `event_whitelist` node at:
 * http://site-config.hulu.com/dev?name=client_metrics_config
 */

export const SUBSCRIPTION_PLAN_SELECT = 'subscription_plan_select';
export const SUBSCRIPTION_PAYMENT_ERROR = 'subscription_payment_error';
export const PAGE_IMPRESSION = 'page_impression';
export const USER_LOGIN = 'user_login';
export const USER_INTERACTION = 'user_interaction';
export const LOGIN_START = 'login_start';

// User login auth_method types sent to Site Metrics
export const AUTH_METHOD = {
  USERNAME_PASSWORD: 'username_password',
  NEW_SUBSCRIPTION: 'new_subscription',
};

/*
 * Tealium Events
 *
 * Sent by sufo-redux TealiumMetricsTracker
 */

// Billing page events sent to Tealium
export const BILLING = {
  CHANGE_PLAN: 'billing_change_plan',
  REMOVE_ADDON_BUNDLE: 'billing_remove_addon_bundle',
  REMOVE_ADDON: 'billing_remove_addon',
  DETAILS_CLICK: 'billing_details_click',
};

export const SUPERBUNDLE_ELIGIBILITY_CHECK = 'superbundle_eligibility_check';
export const BUNDLE_PARTNER_SWITCH_INELIGIBLE =
  'bundle_partner_switch_ineligible';
export const FROM_PARAM_LANDING = 'from_param_landing';
